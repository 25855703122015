import { Message } from 'primeng/components/common/message';
import { AttachmentsDialogComponent } from './../attachments-dialog/attachments-dialog.component';
import { Subscription } from 'rxjs';
import { Input } from '@angular/core';
import { TimeTrackingService } from './../../../services/time-tracking.service';
import { TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TimeSheetDialogComponent } from '../timesheet-dialog/timesheet-dialog.component';

@Component({
  selector: 'ticket-dialog',
  templateUrl: './ticket-dialog.component.html'
})
export class TicketDialogComponent implements OnInit {
  @Input() ticketId: any;
  public companyId = sessionStorage.getItem("compId")

  public systemsWithoutSla;
  public systemsWithSla = [];
  public statuses;
  public systems;
  public priorities;
  public users;
  public usersAssignable;

  //UTILS
  newVersionModalRef: NgbModalRef;
  logDescMOdalRef: NgbModalRef;
  cancelReasonModalRef: NgbModalRef;
  busy: Subscription;
  disablePriority: boolean;
  disableSystem: boolean;
  isCreate: boolean;
  filterLogs: boolean;
  isResolved: boolean;
  logTitle: string;
  titleHeader: string;

  //FIELDS
  slaResponseTime: string;
  slaResolutionTime: string;
  shortDescription: string;
  description: string;
  solution: string;
  tihiDescription: string;
  openingDate: string;
  dueTimeResponse: string;
  dueTimeResolution: string;
  lastUpdate: string;
  cancelReason: string;
  solutionOrCancelReason: string;

  firstResponseDate: string;
  systemName: string;
  responsibleName: string;
  requestUser: string;
  requestUserOld: string;
  system: any;
  sla: any = '';
  currentUser: any;
  ticket: any;
  customers: any;
  event: any;
  eventComment: any;
  logDescription: any;

  shortDescriptionOld: string;
  descriptionOld: string;
  responsibleNameOld: string;
  systemNameOld: string;
  solutionOld: string;
  systemUserOld: string;
  serviceTimeOld: string;
  dueTimeResponseColor: string;
  dueTimeResolutionColor: string;

  ticketHistories: any;
  ticketHistoriesComment: any;
  commentHistories: any;
  fullLog: any;
  systemSelected: any;
  systemUser: any;
  customerSelected: any;
  customerName: any;
  responsibleSelected: any;
  requestUserSelected: any;
  requestUserSelectedOld: any;
  prioritySelected: any;
  statusName: any;
  tihiCols: any;
  responsibleSelectedOld: any;
  systemSelectedOld: any;
  customerSelectedOld: any;
  prioritySelectedOld: any;
  categories: any;
  categorySelected: any;
  categorySelectedOld: any;
  complexities: any;
  complexitiesSelected: any;
  complexitiesSelectedOld: any;
  serviceTime: any;

  alertsDismiss: any[] = [];
  ticketChanges: string[];

  totalPages: number;
  totalElements: number;
  totalPagesComment: number;
  totalElementsComment: number;

  openingDateObj: { year: number; month: number; day: number; hour: number; minute: number; };
  dueTimeResponseObj: { year: number; month: number; day: number; hour: number; minute: number; };
  dueTimeResolutionObj: { year: number; month: number; day: number; hour: number; minute: number; };
  lastUpdateObj: { year: number; month: number; day: number; hour: number; minute: number; };

  constructor(private ttService: TimeTrackingService, private ngbModal: NgbModal, private modalRef: NgbActiveModal) { }

  ngOnInit() {
    sessionStorage.setItem('padoName', 'TICKET');
    sessionStorage.setItem('paraName', 'TICKET');

    this.titleHeader = 'New Ticket';

    this.disablePriority = true;
    this.disableSystem = true;
    this.isCreate = true;

    this.systemSelected = '';
    this.customerSelected = '';
    this.prioritySelected = '';
    this.categorySelected = 'Information';
    this.complexitiesSelected = 'Low';
    this.complexitiesSelectedOld = '';
    this.statusName = 'Open';
    this.responsibleSelected = '';
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    //this.requestUser          = this.currentUser.fullName;
    this.requestUserSelected = '';
    this.slaResponseTime = '';
    this.slaResolutionTime = '';
    this.shortDescription = '';
    this.description = '';
    this.solution = '';
    this.tihiDescription = '';
    this.serviceTime = '';
    this.serviceTimeOld = '';
    this.systemUser = '';

    this.getPriorities();
    this.getUsers();
    this.getUsersByProfile();
    this.getCustomer(-1);

    this.tihiCols = [
      // { field: 'id',     header: 'HistoryS ID' },
      { field: 'date', header: 'Date' },
      { field: 'userName', header: 'User' },
      { field: 'action', header: 'Action' },
      { field: 'description', header: 'Description' }

    ];

    this.categories = [
      { name: "Incident" },
      { name: "Information" },
      { name: "Improvement" },
      { name: "Problem" },
      { name: "Request" }
    ];

    this.complexities = [
      { name: "Low" },
      { name: "Medium" },
      { name: "High" }
    ];

    if (this.ticketId) {
      this.getTicket(this.ticketId);
      this.getStatus();
      this.isCreate = false;
    }
  }

  //UTILS
  dateObjectToString(date) {
    return date.year + "/" + ("0" + date.month).slice(-2) + "/" + ("0" + date.day).slice(-2) + " - " + ("0" + date.hour).slice(-2) + ":" + ("0" + date.minute).slice(-2);
  }

  timeToDecimal(time) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  getDueInputColor(dueTime, openingDate, condition) {
    let currentTime = new Date().getTime();
    /*Cálculo do tempo total = data de vencimento da resposta - data de abertura do ticket.
      Cálculo do tempo restante = data de vencimento da resposta - data atual.
      Cálculo do tempo gasto = tempo total - tempo restante.*/
    let totalTime = dueTime - openingDate;
    let remainingTime = dueTime - currentTime;
    let elapsedTime = currentTime - openingDate;

    //Cálculo da porcentagem
    let responsePercentage = (elapsedTime * 100) / totalTime;
    /*Definição das cores a partir da porcentagem de tempo decorrido:
    - De 0% à 70% do tempo decorrido o campo fica verde;
    - De 71% à 99% do tempo decorrido o campo fica amarelo;
    - A partir de 100% do tempo decorrido o campo ficará vermelho.*/

    console.log('Elapsed time',elapsedTime);
    console.log('dueTime',dueTime)
    console.log('currentTime (ms)',currentTime);
    console.log('openingDate (ms)',openingDate)
    console.log('currentTime (dt)',new Date(currentTime));
    console.log('opening date (dt)',new Date(openingDate))
    if (condition == null) {
      if (responsePercentage >= 0 && responsePercentage < 71) {
        return "#48C9B0";
      } else if (responsePercentage >= 71 && responsePercentage < 100) {
        return "#F4D03F";
      } else {
        return "#EC7063";
      }
    } else {
      return "";
    }
  }

  isTicketChanged(ticket) {
    console.log(ticket);
    let isTicketChanged = false;
    this.ticketChanges = new Array();

    if (this.responsibleSelected != ticket.responsible.id) {
      this.ticketChanges.push("\n - Responsible")
      isTicketChanged = true;
    }
    if (this.systemSelected != ticket.sla.system.id) {
      this.ticketChanges.push("\n - System")
      isTicketChanged = true;
    }
    let customer = ticket.customer.custId == null ? '' : ticket.customer.custId;
    if (this.customerSelected != customer) {
      this.ticketChanges.push("\n - Customer")
      isTicketChanged = true;
    }
    if (this.prioritySelected != ticket.sla.prioritySla.id) {
      this.ticketChanges.push("\n - Priority")
      isTicketChanged = true;
    }
    if (this.shortDescription != ticket.shortDescription) {
      this.ticketChanges.push("\n - Short Description")
      isTicketChanged = true;
    }
    if (this.description != ticket.description) {
      this.ticketChanges.push("\n - Description")
      isTicketChanged = true;
    }
    if (this.solution != ticket.solution) {
      this.ticketChanges.push("\n - Solution")
      isTicketChanged = true;
    }
    let systemUser = ticket.systemUser == null ? '' : ticket.systemUser;
    if(this.systemUser != systemUser){
      this.ticketChanges.push("\n - System User")
      isTicketChanged = true;
    }
    let serviceTime = ticket.serviceTime == null ? '' : ticket.serviceTime;
    if(this.serviceTime != serviceTime){
      this.ticketChanges.push("\n - Service Time")
      isTicketChanged = true;
    }

    return isTicketChanged;
  }

  fillTicket(ticket) {
    let statusName = ticket.ticketStatus.name;
    this.ticket = ticket;
    this.sla = ticket.sla;
    this.filterLogs = true;
    this.isResolved = false;
    this.disableSystem = false;
    this.dueTimeResponseColor = '';
    this.dueTimeResolutionColor = '';
    this.cancelReason = ''
    
    this.solutionOrCancelReason = statusName == 'Canceled' ? "Cancel Reason" : "Solution";

    if (statusName == 'Canceled' || statusName == 'Resolved') {
      this.isResolved = true;
    }

    if (ticket.requestUser) {
      this.requestUserSelected = ticket.requestUser.id;
      this.requestUserSelectedOld = this.requestUserSelected;
      this.requestUserOld = ticket.requestUser.fullName == null ? '' : ticket.requestUser.fullName;
      this.requestUser = this.requestUserOld;
    }

    if(ticket.systemUser){
      this.systemUser= ticket.systemUser;
      this.systemUserOld = this.systemUser;
    }

    if(ticket.serviceTime){
      this.serviceTime= ticket.serviceTime;
      this.serviceTimeOld = this.serviceTime;
    }

    if (ticket.openingDate != null) {
      let openingDate = new Date(ticket.openingDate)
      this.openingDateObj = {
        year: openingDate.getFullYear(),
        month: openingDate.getMonth() + 1,
        day: openingDate.getDate(),
        hour: openingDate.getHours(),
        minute: openingDate.getMinutes()
      };
      this.openingDate = this.dateObjectToString(this.openingDateObj);
    }

    if (ticket.dueTimeResolution != null) {
      let dueTimeResolution = new Date(ticket.dueTimeResolution);
      this.dueTimeResolutionObj = {
        year: dueTimeResolution.getFullYear(),
        month: dueTimeResolution.getMonth() + 1,
        day: dueTimeResolution.getDate(),
        hour: dueTimeResolution.getHours(),
        minute: dueTimeResolution.getMinutes()
      };
      this.dueTimeResolution = this.dateObjectToString(this.dueTimeResolutionObj);
    }

    if (ticket.dueTimeResponse != null) {
      let dueTimeResponse = new Date(ticket.dueTimeResponse);
      this.dueTimeResponseObj = {
        year: dueTimeResponse.getFullYear(),
        month: dueTimeResponse.getMonth() + 1,
        day: dueTimeResponse.getDate(),
        hour: dueTimeResponse.getHours(),
        minute: dueTimeResponse.getMinutes()
      };
      this.dueTimeResponse = this.dateObjectToString(this.dueTimeResponseObj);
    }

    if (ticket.lastUpdated != null) {
      let lastUpdated = new Date(ticket.lastUpdated);
      this.lastUpdateObj = {
        year: lastUpdated.getFullYear(),
        month: lastUpdated.getMonth() + 1,
        day: lastUpdated.getDate(),
        hour: lastUpdated.getHours(),
        minute: lastUpdated.getMinutes()
      };
      this.lastUpdate = this.dateObjectToString(this.lastUpdateObj);
    }

    if (this.event) {
      this.getTicketHistory(this.ticketId + '?page=' + this.event.page + '&size=10');
    } else {
      this.getTicketHistory(this.ticketId + '?page=&size=10');
    }

    if (ticket.shortDescription) {
      this.shortDescription = ticket.shortDescription;
    } else {
      this.shortDescription = '';
    }

    this.description = ticket.description;

    if (ticket.solution) {
      this.solution = ticket.solution;
    } else {
      this.solution = '';
    }

    this.solutionOld = this.solution;
    this.descriptionOld = this.description;
    this.shortDescriptionOld = this.shortDescription;

    if (ticket.customer) {
      this.getCustomer(ticket.customer.custId);
      this.customerName = ticket.customer.custName;
      this.customerSelected = ticket.customer.custId == null ? '' : ticket.customer.custId;
      this.customerSelectedOld = this.customerSelected;
    }

    if (this.customerSelected != "" && this.customerSelected != null) {
      if (ticket.sla) {
        if (ticket.sla.system) {
          let fetchedSystem = (ticket.sla.system.id == null ? -1 : ticket.sla.system.id);
          this.getSystemsByCustomer(this.customerSelected, fetchedSystem);
          this.system = this.ticket.sla.system;
          this.disablePriority = false;
          this.disableSystem = false;
          this.systemSelected = ticket.sla.system.id == null ? '' : ticket.sla.system.id;
          this.systemSelectedOld = this.systemSelected;
          this.systemNameOld = ticket.sla.system.name == null ? '' : ticket.sla.system.name;
          this.systemName = this.systemNameOld;
        }
      } else {
        this.getSystemsByCustomer(this.customerSelected, -1);
      }
    }

    if (ticket.responsible) {
      this.responsibleSelected = ticket.responsible.id;
      this.responsibleSelectedOld = this.responsibleSelected;
      this.responsibleNameOld = ticket.responsible.fullName == null ? '' : ticket.responsible.fullName;
      this.responsibleName = this.responsibleNameOld;
    }

    this.statusName = ticket.ticketStatus.name == null ? '' : ticket.ticketStatus.name;

    this.firstResponseDate = ticket.firstResponse;

    if (ticket.sla) {
      if (ticket.sla.prioritySla) {
        this.prioritySelected = ticket.sla.prioritySla.id;
        this.prioritySelectedOld = this.prioritySelected
      }
    }

    if (ticket.category) {
      this.categorySelected = ticket.category;
      this.categorySelectedOld = ticket.category;
    }

    if (ticket.complexity) {
      this.complexitiesSelected = ticket.complexity;
      this.complexitiesSelectedOld = ticket.complexity;
    }

    if (ticket.sla) {
      this.slaResolutionTime = ticket.sla.timeToConcluse;
      this.slaResponseTime = ticket.sla.firstResponse;
    }

    if (this.ticketId) {
      this.titleHeader = 'View Ticket # ' + this.ticketId;
    }

    if (ticket.dueTimeResponse && ticket.openingDate) {
      let dueTimeResponse = ticket.dueTimeResponse;
      let openingDate = ticket.openingDate;
      let firstResponse = ticket.firstResponse;
      let ticketStatus = ticket.ticketStatus.name;
      let resolved = ticketStatus == 'Resolved' ? 'Ticket is closed' : null;
      let condition = resolved != null || firstResponse != null ? 'Ticket is closed or already have a first response' : null;

      this.dueTimeResponseColor = this.getDueInputColor(dueTimeResponse, openingDate, condition);
    }

    if (ticket.dueTimeResolution && ticket.openingDate) {
      let dueTimeResolution = ticket.dueTimeResolution;
      let openingDate = ticket.openingDate;
      let ticketStatus = ticket.ticketStatus.name;
      let condition = ticketStatus == 'Resolved' ? 'Ticket is closed' : null;

      this.dueTimeResolutionColor = this.getDueInputColor(dueTimeResolution, openingDate, condition);
    }
  }

  fieldChanged(event, param) {
    if (param == "Customer") {
      this.disableSystem = false;
      if (this.ticket && this.ticket.sla) {
        let fetchedSystem = (this.ticket.sla.system.id == null ? -1 : this.ticket.sla.system.id);
        this.getSystemsByCustomer(this.customerSelected, fetchedSystem);
      } else {
        this.getSystemsByCustomer(this.customerSelected, -1);
      }
    }

    if (param == "System") {
      this.disablePriority = false;
      this.getTicketSla();
    }

    if (param == "Priority") {
      this.getTicketSla();
    }
  }

  saveTicket() {
    if (!this.ticketId || this.ticketId == 0) {
      this.createTicket();
    } else {
      this.updateTicket();
    }
  }

  saveComment() {
    if (!this.ticket.responsible) {
      alert('You should add a responsible first')
    }
    else {
      this.createTicketHistory('COMMENT');
      this.ticket.comment = this.tihiDescription;
      this.send(this.ticket, "Comment");
      this.newVersionModalRef.close('');
      if (this.ticketId) {
        this.getTicket(this.ticketId);
        this.getStatus();
        this.isCreate = false;
      }
      // this.updateTicket();
      alert('Comment is saved.');
    }
  }

  checkCancel(template: TemplateRef<any>, statusId, statusName) {
    if (statusName == 'Canceled') {
      this.cancelReasonModalRef = this.ngbModal.open(template);
      this.cancelReasonModalRef.result.then(
        () => {
          this.changeStatus(statusId, statusName);
        }
      );
    } else {
      this.cancelReason = '';
      this.changeStatus(statusId, statusName)
    }
  }

  validateCancelReason() {
    if (this.cancelReason.trim() == '' || this.cancelReason == null || this.cancelReason == undefined) {
      alert("The cancel reason must be provided.")
    } else {
      this.cancelReasonModalRef.close('');
    }
  }

  //GETS (REQUESTS)
  getTicket(ticketId) {
    this.busy = this.ttService.getTicket(ticketId).subscribe(
      data => { this.fillTicket(data) });
  }

  getTicketSla() {
    this.busy = this.ttService.getSlaByPriorityAndSystem(this.prioritySelected, this.systemSelected).subscribe(
      data => {
        this.sla = data["slas"][0];
        this.system = this.sla.system;
        this.slaResponseTime = this.sla.firstResponse;
        this.slaResolutionTime = this.sla.timeToConcluse;
      }
    );
  }

  getCustomer(customerId) {
    this.busy = this.ttService.getCustomersAlphabeticalOrder(sessionStorage.getItem("compId"), customerId).subscribe(
      data => {
        this.customers = data["customer"];
      },
      err => {
        this.ttService.checkErr(err, 'error get Customer', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.errorerrorMessage,
          timeout: 5000
        });
      },
      () => console.log("Done loading Systems")
    )
  }

  getTicketHistory(pagination) {
    this.busy = this.ttService.getTicketHistory(pagination).subscribe(
      data => {
        let historyDate;
        this.ticketHistories = data["ticketLogs"];
        for (let history of this.ticketHistories) {
          history["userName"] = history.author.fullName;
          //mudei aqui e ta funcionando

          historyDate = new Date();
          historyDate.setTime(history.dateCreated);
          history["date"] = historyDate.getFullYear() + "-" + ("0" + (historyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + historyDate.getDate()).slice(-2) + " - " + ("0" + historyDate.getHours()).slice(-2) + ":" + ("0" + historyDate.getMinutes()).slice(-2);
          history["ticketId"] = history.ticket.id;
        }


        this.totalPages = data["pageNumber"];
        this.totalElements = data["elementNumber"];

        if (this.eventComment) {
          this.getTicketCommentHistories(this.ticketId + '?page=' + this.eventComment.page + '&size=10');
        } else {
          this.getTicketCommentHistories(this.ticketId + '?page=&size=10');
        }
        this.fullLog = this.ticketHistories;
      }
    );
  }

  getTicketCommentHistories(pagination) {
    this.busy = this.ttService.getTicketCommentHistories(pagination).subscribe(
      data => {
        let historyDate;
        this.ticketHistoriesComment = data["ticketLogs"];
        for (let history of this.ticketHistoriesComment) {
          history["userName"] = history.author.fullName;

          historyDate = new Date();
          historyDate.setTime(history.dateCreated);
          history["date"] = historyDate.getFullYear() + "-" + ("0" + (historyDate.getMonth() + 1)).slice(-2) + "-" + ("0" + historyDate.getDate()).slice(-2) + " - " + ("0" + historyDate.getHours()).slice(-2) + ":" + ("0" + historyDate.getMinutes()).slice(-2);
          history["ticketId"] = history.ticket.id;
        }

        this.totalPagesComment = data["pageNumber"];
        this.totalElementsComment = data["elementNumber"];
        this.commentHistories = this.ticketHistoriesComment;
      }
    );
  }

  getStatus() {
    this.busy = this.ttService.getTicketStatus().subscribe(
      data => { this.statuses = data['ticketStatus']; });
  }

  getPriorities() {
    this.busy = this.ttService.getPrioritySlas().subscribe(
      data => { this.priorities = data['prioritySlas'] });
  }

  getUsers() {
    this.busy = this.ttService.getUserByCompId(sessionStorage.getItem("compId")).subscribe(
      data => {
        this.users = data;
      });
  }

  /* Setado em pefil analista, mas o ideal é passar como parâmetro */
  getUsersByProfile() {
    this.busy = this.ttService.getActiveUsers(sessionStorage.getItem("compId")).subscribe(
      data => {
        this.usersAssignable = data;
      });
  }


  getSystemsByCustomer(customer, systemId) {
    this.busy = this.ttService.getSystemsByCustomerAndSystemId(customer, systemId).subscribe(
      data => {
        this.systems = data["systems"];
        this.getSystemsWithoutSla();
      }
    );
  }

  getSystems() {
    this.busy = this.ttService.getSystems(this.companyId).subscribe(
      data => {
        this.systems = data["systems"];
        this.getSystemsWithoutSla();
      }
    );
  }

  getSystemsWithoutSla() {
    this.busy = this.ttService.getSystemsWithoutSla(this.companyId).subscribe(
      data => {
        let match = false;
        this.systemsWithSla = [];
        this.systemsWithoutSla = data["systems"];
        for (let system of this.systems) {
          match = false;
          for (let systemWithoutSla of this.systemsWithoutSla) {
            if (system.id == systemWithoutSla.id) {
              match = true;
            }
          }
          if (!match) {
            this.systemsWithSla.push(system);
          }
        }
      },
      err => {
        console.log(err)
        this.ttService.checkErr(err, 'error loading systems without sla', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });
      },
      () => console.log('done loading systems without sla')
    );
  }

  //CREATES
  createTicket() {
    let requestUser = { "id": this.requestUserSelected };
    let responsible = { "id": this.responsibleSelected };
    this.system = { "id": this.systemSelected };
    if (this.systemSelected == "") {
      this.system = null;
    }

    // if(this.customerSelected == "" || this.customerSelected == null){
    //   this.alertsDismiss.push({
    //     type: 'danger',
    //     msg: "Please inform the customer",
    //     timeout: 5000
    //   });
    // }

    this.busy = this.ttService.createTicket({
      "shortDescription": this.shortDescription,
      "description": this.description,
      "solution": this.solution,
      "requestUser": requestUser,
      "systemUser": this.systemUser,
      "responsible": responsible,
      "sla": this.sla,
      "category": this.categorySelected,
      "complexity": this.complexitiesSelected,
      "ticketStatus": { 'id': 1 },
      "company": { 'id': this.companyId },
      "customer": { 'custId': this.customerSelected },
      "serviceTime": this.serviceTime
    }).subscribe(
      response => {
        this.ticketId = response["id"];
        this.tihiDescription = 'Created Ticket ' + this.ticketId;
        this.createTicketHistory('INSERT');
        alert('Ticket is saved.');
        this.send(response, "Open");
        this.close();
      },
      err => {
        if(err.error.message == 'Não há feriados cadastrados para este ano') {
          alert("Não há feriados cadastrados para este ano. Por favor, realize uma carga no banco de dados.")
        }
        //this.ttService.checkErr(err,'error create ticket', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.errorerrorMessage,
          timeout: 5000
        });

      });
  }

  createTicketHistory(action) {
    this.ttService.createTicketHistory({
      'action': action,
      'description': this.tihiDescription
    }, this.ticketId, sessionStorage.getItem('userId')).subscribe(
      response => {
        this.tihiDescription = '';
        if (this.event) {
          this.getTicketHistory(this.ticketId + '?page=' + this.event.page + '&size=10');
        } else {
          this.getTicketHistory(this.ticketId + '?page=&size=10');
        }
      },
      err => {
        this.ttService.checkErr(err, 'error loading system history', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });
      },
    );
  }
  validField(field:string){
    if(field=='' || field == null || field == undefined){
      return false;
    }
    return true;
  }
  //UPDATES
  changeStatus(statusId, statusName) {
    if (this.ticket.responsible == null) {
      if (this.responsibleSelected != '')
        alert('É preciso salvar o ticket antes de alterar o status.')
      else
        alert('A responsible must be selected before changing the ticket status.');
    } else {
      if (statusName == 'Resolved' && (!this.validField(this.ticket.solution) || !this.validField(this.ticket.serviceTime))) {
        alert('The "Solution" and "Service Time" fields must be filled out before changing the ticket status to Resolved.');
      } else {
        if (this.ticket.customer == null) {
          alert('A customer must be selected before changing the ticket status.');
        } else {
          if (this.ticket.sla.system == null) {
            alert('A system must be selected before changing the ticket status.');
          } else {
            this.ticket.customer = { "custId": this.customerSelected };

            let confirmation;
            let openingDateObj;
            let dueTimeResponseObj;
            let dueTimeResolutionObj;
            let lastUpdateObj;

            let ticketStatus = { "id": statusId };

            let responsible = { "id": this.ticket.responsible.id };
            if (this.ticket.responsible.id == "") {
              responsible = null;
            }

            let requestUser = { "id": this.ticket.requestUser.id };
            if (this.ticket.requestUser.id == "") {
              requestUser = null;
            }

            let sla = { "id": this.ticket.sla.id };
            if (this.ticket.sla.id == "") {
              sla = null;
            }

            if (this.ticket.ticketStatus.name == 'Open' && statusName == 'Open') {
              alert("This ticket is already open!");
            } else {
              if (this.ticket.ticketStatus.name == 'In Progress' && statusName == 'In Progress') {
                alert("This ticket is already in progress!");
              } else {
                if (this.ticket.ticketStatus.name == 'Pending Customer' && statusName == 'Pending Customer') {
                  alert("This ticket is already pending customer!");
                } else {
                  if (this.ticket.ticketStatus.name == 'Pending Supplier' && statusName == 'Pending Supplier') {
                    alert("This ticket is already pending supplier!");
                  } else {

                    if (this.openingDateObj && this.openingDateObj.day && this.openingDateObj.month) {
                      openingDateObj = new Date();
                      openingDateObj.setFullYear(this.openingDateObj.year);
                      openingDateObj.setDate(this.openingDateObj.day);
                      openingDateObj.setMonth(this.openingDateObj.month - 1);
                      openingDateObj.setHours(this.openingDateObj.hour);
                      openingDateObj.setMinutes(this.openingDateObj.minute);
                    }

                    if (this.lastUpdateObj && this.lastUpdateObj.day && this.lastUpdateObj.month) {
                      lastUpdateObj = new Date();
                      lastUpdateObj.setFullYear(this.lastUpdateObj.year);
                      lastUpdateObj.setDate(this.lastUpdateObj.day);
                      lastUpdateObj.setMonth(this.lastUpdateObj.month - 1);
                      lastUpdateObj.setHours(this.lastUpdateObj.hour);
                      lastUpdateObj.setMinutes(this.lastUpdateObj.minute);
                    }

                    if (this.dueTimeResponseObj && this.dueTimeResponseObj.day && this.dueTimeResponseObj.month) {
                      dueTimeResponseObj = new Date();
                      dueTimeResponseObj.setFullYear(this.dueTimeResponseObj.year);
                      dueTimeResponseObj.setDate(this.dueTimeResponseObj.day);
                      dueTimeResponseObj.setMonth(this.dueTimeResponseObj.month - 1);
                      dueTimeResponseObj.setHours(this.dueTimeResponseObj.hour);
                      dueTimeResponseObj.setMinutes(this.dueTimeResponseObj.minute);
                    }

                    if (this.dueTimeResolutionObj && this.dueTimeResolutionObj.day && this.dueTimeResolutionObj.month) {
                      dueTimeResolutionObj = new Date();
                      dueTimeResolutionObj.setFullYear(this.dueTimeResolutionObj.year);
                      dueTimeResolutionObj.setDate(this.dueTimeResolutionObj.day);
                      dueTimeResolutionObj.setMonth(this.dueTimeResolutionObj.month - 1);
                      dueTimeResolutionObj.setHours(this.dueTimeResolutionObj.hour);
                      dueTimeResolutionObj.setMinutes(this.dueTimeResolutionObj.minute);
                    }

                    if (this.isTicketChanged(this.ticket)) {
                      if (confirm("The following changes wasn't saved:" + this.ticketChanges + "\n Proceed?")) {
                        confirmation = true;
                      } else {
                        confirmation = false;
                      }
                    } else {
                      confirmation = true;
                    }

                    if (this.cancelReason != '') {
                      this.ticket.solution = this.cancelReason;
                    }
                    console.log(this.companyId);
                    if (confirmation) {
                      this.busy = this.ttService.updateTicket({
                        "responsible": responsible,
                        "openingDate": openingDateObj,
                        "dueTimeResponse": dueTimeResponseObj,
                        "dueTimeResolution": dueTimeResolutionObj,
                        "lastUpdate": lastUpdateObj,
                        "sla": sla,
                        "ticketStatus": ticketStatus,
                        "requestUser": requestUser,
                        "shortDescription": this.ticket.shortDescription,
                        "description": this.ticket.description,
                        "solution": this.ticket.solution,
                        "firstResponse": this.firstResponseDate,
                        "customer": { 'custId': this.customerSelected },
                        "systemUser": this.systemUser,
                        "serviceTime": this.serviceTime,
                        "category": this.categorySelected,
                        "complexity": this.complexitiesSelected,
                        "company": { 'id': this.companyId },
                      }, this.ticketId).subscribe(
                        response => {

                          this.tihiDescription = "'Status' changed from [" + this.ticket.ticketStatus.name + "] to [" + statusName + "]";

                          alert('The status of ticket ' + this.ticketId + ' is changed.');

                          if ((this.ticket.ticketStatus.name == "Pending Customer" || this.ticket.ticketStatus.name == "Pending Supplier") && (statusName == "In Progress")) {
                            this.send(this.ticket, "Pending Supplier And Customer");
                          } else {
                            this.send(this.ticket, statusName);
                          }

                          this.getTicket(this.ticketId);

                          this.createTicketHistory('STATUS');
                        }
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  send(ticket, statusName) {
    this.busy = this.ttService.send(ticket, statusName).subscribe(
      data => {
        console.log('data');
        console.log(data);
      },
      err => {
        this.ttService.checkErr(err, 'error send email', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.errorerrorMessage,
          timeout: 5000
        });
      },
    )
  }

  updateTicket() {
    let openingDate;
    let dueTimeResponse;
    let dueTimeResolution;
    let lastUpdate;

    let ticketStatus = { "id": this.ticket.ticketStatus.id };
    if (this.ticket.ticketStatus.id == "") {
      ticketStatus = null;
    }

    let responsible = { "id": this.responsibleSelected };
    if (this.responsibleSelected == "") {
      responsible = null;
    }

    let requestUser = { "id": this.requestUserSelected };
    if (this.requestUserSelected == "") {
      requestUser = null;
    }

    let sla = { "id": this.sla.id };
    if (this.sla.id == "") {
      sla = null;
    }

    let customer = { 'custId': this.customerSelected }
    if (this.customerSelected == "") {
      customer = null;
    }

    if (this.openingDateObj && this.openingDateObj.day && this.openingDateObj.month) {
      openingDate = new Date();
      openingDate.setFullYear(this.openingDateObj.year);
      openingDate.setMonth(this.openingDateObj.month - 1);
      openingDate.setDate(this.openingDateObj.day);
      openingDate.setHours(this.openingDateObj.hour);
      openingDate.setMinutes(this.openingDateObj.minute);
    }

    if (this.dueTimeResponseObj && this.dueTimeResponseObj.day && this.dueTimeResponseObj.month) {
      dueTimeResponse = new Date();
      dueTimeResponse.setFullYear(this.dueTimeResponseObj.year);
      dueTimeResponse.setMonth(this.dueTimeResponseObj.month - 1);
      dueTimeResponse.setDate(this.dueTimeResponseObj.day);
      dueTimeResponse.setHours(this.dueTimeResponseObj.hour);
      dueTimeResponse.setMinutes(this.dueTimeResponseObj.minute);
    }

    if (this.dueTimeResolutionObj && this.dueTimeResolutionObj.day && this.dueTimeResolutionObj.month) {
      dueTimeResolution = new Date();
      dueTimeResolution.setFullYear(this.dueTimeResolutionObj.year);
      dueTimeResolution.setMonth(this.dueTimeResolutionObj.month - 1);
      dueTimeResolution.setDate(this.dueTimeResolutionObj.day);
      dueTimeResolution.setHours(this.dueTimeResolutionObj.hour);
      dueTimeResolution.setMinutes(this.dueTimeResolutionObj.minute);
    }

    if (this.lastUpdateObj && this.lastUpdateObj.day && this.lastUpdateObj.month) {
      lastUpdate = new Date();
      lastUpdate.setFullYear(this.lastUpdateObj.year);
      lastUpdate.setMonth(this.lastUpdateObj.month - 1);
      lastUpdate.setDate(this.lastUpdateObj.day);
      lastUpdate.setHours(this.lastUpdateObj.hour);
      lastUpdate.setMinutes(this.lastUpdateObj.minute);
    }

    this.busy = this.ttService.updateTicket({
      "serviceTime": this.serviceTime,
      "responsible": responsible,
      "openingDate": openingDate,
      "dueTimeResponse": dueTimeResponse,
      "dueTimeResolution": dueTimeResolution,
      "lastUpdate": lastUpdate,
      "sla": sla,
      "ticketStatus": ticketStatus,
      "requestUser": requestUser,
      "systemUser": this.systemUser,
      "shortDescription": this.shortDescription,
      "description": this.description,
      "solution": this.solution,
      "firstResponse": this.firstResponseDate,
      "category": this.categorySelected,
      "complexity": this.complexitiesSelected,
      "company": { 'id': this.companyId },
      "customer": customer
    }, this.ticketId).subscribe(
      response => {
        console.log(response);
        let desc: string;
        this.tihiDescription = '';

        if (this.shortDescription != this.shortDescriptionOld) {
          desc = "'Short Description' changed from [" + this.shortDescriptionOld + "] to [" + this.shortDescription + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }

        if (this.description.length >= 300 && this.descriptionOld.length <= 300) {
          if (this.description != this.descriptionOld) {
            desc = "'Description' changed from [" + this.descriptionOld.substring(0, 300) + " ] to [" + this.description.substring(0, 300) + "...]";
            if (this.tihiDescription != '') {
              this.tihiDescription += ", "
            }
            this.tihiDescription += desc;
          }

        }

        if (this.description.length >= 300 && this.descriptionOld.length >= 300) {
          if (this.description != this.descriptionOld) {
            desc = "'Description' changed from [" + this.descriptionOld.substring(0, 300) + " ...] to [" + this.description.substring(0, 300) + "...]";
            if (this.tihiDescription != '') {
              this.tihiDescription += ", "
            }
            this.tihiDescription += desc;
          }

        }

        if (this.description.length <= 300 && this.descriptionOld.length >= 300) {
          if (this.description != this.descriptionOld) {
            desc = "'Description' changed from [" + this.descriptionOld.substring(0, 300) + " ...] to [" + this.description.substring(0, 300) + "]";
            if (this.tihiDescription != '') {
              this.tihiDescription += ", "
            }
            this.tihiDescription += desc;
          }

        }

        if (this.description != this.descriptionOld && this.description.length <= 300 && this.descriptionOld.length <= 300) {
          desc = "'Description' changed from [" + this.descriptionOld + "] to [" + this.description + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }



        if (this.solution.length >= 300 && this.solutionOld.length <= 300) {
          if (this.solution != this.solutionOld) {
            desc = "'Solution' changed from [" + this.solutionOld.substring(0, 300) + " ] to [" + this.solution.substring(0, 300) + "...]";
            if (this.tihiDescription != '') {
              this.tihiDescription += ", "
            }
            this.tihiDescription += desc;
          }

        }

        if (this.solution.length >= 300 && this.solutionOld.length >= 300) {
          if (this.solution != this.solutionOld) {
            desc = "'Solution' changed from [" + this.solutionOld.substring(0, 300) + " ...] to [" + this.solution.substring(0, 300) + "...]";
            if (this.tihiDescription != '') {
              this.tihiDescription += ", "
            }
            this.tihiDescription += desc;
          }

        }

        if (this.solution.length <= 300 && this.solutionOld.length >= 300) {
          if (this.solution != this.solutionOld) {
            desc = "'Solution' changed from [" + this.solutionOld.substring(0, 300) + " ...] to [" + this.solution.substring(0, 300) + "]";
            if (this.tihiDescription != '') {
              this.tihiDescription += ", "
            }
            this.tihiDescription += desc;
          }

        }



        if (this.solution != this.solutionOld && this.solution.length <= 300 && this.solutionOld.length <= 300) {
          desc = "'Solution' changed from [" + this.solutionOld + "] to [" + this.solution + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }

        if (this.customerSelected != this.customerSelectedOld) {
          desc = "'Customer' changed from [" + this.customerSelectedOld + "] to [" + this.customerSelected + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }

        if (this.systemSelected != this.systemSelectedOld) {
          this.systemName = this.system.name;
          desc = "'System' changed from [" + this.systemNameOld + "] to [" + this.systemName + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }

        if (this.prioritySelected != this.prioritySelectedOld) {
          desc = "'Priority' changed from [" + this.prioritySelectedOld + "] to [" + this.prioritySelected + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }

        if (this.categorySelected != this.categorySelectedOld) {
          desc = "'Category' changed from [" + this.categorySelectedOld + "] to [" + this.categorySelected + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }

        if (this.categorySelected != this.categorySelectedOld) {
          desc = "'Complexity' changed from [" + this.complexitiesSelectedOld + "] to [" + this.complexitiesSelected + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }

        if (this.serviceTime != this.serviceTimeOld) {
          desc = "'Service Time' changed from [" + this.serviceTimeOld + "] to [" + this.serviceTime + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }

        if (this.systemUser != this.systemUserOld) {
          desc = "'System User' changed from [" + this.systemUserOld + "] to [" + this.systemUser + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }

        if (this.responsibleSelected != this.responsibleSelectedOld) {
          for (let responsible of this.users) {
            if (responsible["id"] == this.responsibleSelected) {
              this.responsibleName = responsible["fullName"];
            }
          }

          desc = "'Support User' changed from [" + this.responsibleNameOld + "] to [" + this.responsibleName + "]";

          if (this.tihiDescription != '') {
            this.tihiDescription += ", "
          }
          this.tihiDescription += desc;
        }
        console.log(desc);
        console.log(this.tihiDescription);
        alert('Ticket ' + this.ticketId + ' is saved.');
        if (this.tihiDescription != "") {
          this.createTicketHistory('UPDATE');
        }

        this.fillTicket(response);
      },
      err => {
        this.ttService.checkErr(err, 'error update ticket', this.modalRef);
        this.alertsDismiss.push({
          type: 'danger',
          msg: err.error.errorMessage,
          timeout: 5000
        });
      });
  }

  //OPENS AND CLOSES

  open(template: TemplateRef<any>, ticket, size) {
    this.newVersionModalRef = this.ngbModal.open(template, size);
  }

  openHistory(template: TemplateRef<any>, row) {
    this.logTitle = row.id + " - " + row.action;
    this.populationViewLog(row);
    this.logDescMOdalRef = this.ngbModal.open(template);
  }

  populationViewLog(row) {
    this.logDescription = new Array();
    let positions = new Array();
    if (row.action == "UPDATE") {
      positions.push(parseInt(row.description.indexOf("'Short Description'"), 10));
      positions.push(parseInt(row.description.indexOf("'Description'"), 10));
      positions.push(parseInt(row.description.indexOf("'Solution'"), 10));
      positions.push(parseInt(row.description.indexOf("'Customer'"), 10));
      positions.push(parseInt(row.description.indexOf("'System'"), 10));
      positions.push(parseInt(row.description.indexOf("'Priority'"), 10));
      positions.push(parseInt(row.description.indexOf("'Category'"), 10));
      positions.push(parseInt(row.description.indexOf("'Complexity'"), 10));
      positions.push(parseInt(row.description.indexOf("'Responsible'"), 10));
      positions.push(parseInt(row.description.indexOf("'Support User'"), 10));
      positions.push(parseInt(row.description.indexOf("'System User'"), 10));
      positions.push(parseInt(row.description.indexOf("'Service Time'"), 10));
      positions.push(row.description.length);
      positions.sort(function (a, b) { return a - b; });

      for (let position in positions) {
        let aux = parseInt(position, 10);
        if (positions[aux] != -1 && aux != positions.length - 1) {
          // The last vector it is not necessary to remove the commas
          if (aux == positions.length - 2) {
            // Business rule to show a maximum of 300 characters
            if ((positions[aux + 1] - positions[aux]) > 5000) {
              this.logDescription.push(row.description.substring(positions[aux], positions[aux] + 300));
            } else {
              this.logDescription.push(row.description.substring(positions[aux], positions[aux + 1]));
            }
            // Remove the commas
          } else {
            // Business rule to show a maximum of 300 characters
            if ((positions[aux + 1] - positions[aux]) > 5000) {
              this.logDescription.push(row.description.substring(positions[aux], positions[aux] + 300));
            } else {
              this.logDescription.push(row.description.substring(positions[aux], positions[aux + 1] - 2));
            }
          }
        }
      }
    } else {
      this.logDescription.push(row.description);
    }
  }

  openAttachments(ticket) {
    let modal = this.ngbModal.open(AttachmentsDialogComponent, { size: 'lg' });

    modal.componentInstance.tableId = ticket.id;
    modal.componentInstance.tableName = 'ticket'
    modal.componentInstance.enableUpload = ticket.ticketStatus.name != 'Resolved' ? true : false;

    modal.result.then(response => {
      console.log('response files');
      console.log(response);
      for (let fileLog in response) {
        this.tihiDescription = response[fileLog].description;
        this.send(this.ticket, "Attachments");
        this.createTicketHistory(response[fileLog].action);
        this.updateTicket();
      }
      if (this.event) {
        this.getTicketHistory(this.ticketId + '?page=' + this.event.page + '&size=10');
      } else {
        this.getTicketHistory(this.ticketId + '?page=&size=10');
      }
    });
  }

  openTimeSheet() {
    let modal = this.ngbModal.open(TimeSheetDialogComponent, {
      windowClass: 'sm-modal', backdrop: 'static', keyboard: false
    });

    modal.componentInstance.type = 'Support';
    modal.componentInstance.selectedClient = this.customerSelected;
    modal.componentInstance.selectedSystem = this.systemSelected;
    modal.componentInstance.selectedTicket = this.ticket.id;
    modal.componentInstance.disableEdit = true;
  }

  close() {
    this.modalRef.close('');
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.event = event;
    this.getTicketHistory(this.ticketId + '?page=' + event.page + '&size=10');
  }

  paginateComment(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.eventComment = event;
    this.getTicketCommentHistories(this.ticketId + '?page=' + event.page + '&size=10');
  }
}
